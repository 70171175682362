import React, { useState } from "react";
import { Box, TextField, Autocomplete, ListItemIcon, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IdentificationBadge, MagnifyingGlass, UserCircle } from "@phosphor-icons/react";
import { StatusChip } from "./StatusChip";

export const PeopleFilter = ({
  searchResults,
  filteredPeople,
  setFilteredPeople,
  inputValue,
  setInputValue,
  personScheduleColorMap,
  setPersonScheduleColorMap,
  singlePick,
  label,
  getOptionLabel,
}) => {
  const [colorIndex, setColorIndex] = useState(1);

  const onDeletePerson = (id) => {
    setFilteredPeople(filteredPeople.filter((person) => person.id !== id));

    const { [id]: _, ...newColorMap } = personScheduleColorMap;
    setPersonScheduleColorMap(newColorMap);
  };

  const onAddPerson = (person) => {
    setFilteredPeople(singlePick ? person : [...filteredPeople, person]);

    setPersonScheduleColorMap({
      ...personScheduleColorMap,
      [person.id]: colorIndex,
    });

    setColorIndex((colorIndex + 1) % 9);
  };

  const handleInputChange = (_, newInputValue, reason) => {
    if (reason !== "reset" || singlePick) {
      setInputValue(newInputValue);
    }
  };

  const handleAddChip = (_, selectedUser) => {
    const person = searchResults.find((result) => result.name === selectedUser?.name);

    if (person && !filteredPeople.some((chip) => chip.id === person.id)) {
      onAddPerson(person);
    }
  };

  return (
    <>
      <Autocomplete
        clearOnBlur={!singlePick}
        freeSolo
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={searchResults}
        onChange={handleAddChip}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return (
            <MenuItem {...props}>
              <ListItemIcon>
                {option.isClinician ? (
                  <IdentificationBadge size={"small"} weight="duotone" />
                ) : (
                  <UserCircle size={"small"} weight="duotone" />
                )}
              </ListItemIcon>
              {getOptionLabel?.(option) ?? option?.name}
            </MenuItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            label={label}
            size="small"
            {...params}
            placeholder="Search"
            sx={{
              "& .MuiInputBase-root": {
                width: "100%", // Ensure the input base takes the full width
              },
              "& .MuiOutlinedInput-inputMarginDense": {
                paddingRight: "34px", // Reserve space for the icon to prevent it from moving input text
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: inputValue ? (
                <CloseIcon
                  onClick={() => setInputValue("")}
                  size={20}
                  color="rgba(0, 0, 0, 0.56)"
                  style={{ position: "absolute", right: "10px" }}
                />
              ) : (
                <MagnifyingGlass
                  size={20}
                  color="rgba(0, 0, 0, 0.56)"
                  style={{ position: "absolute", right: "10px" }}
                />
              ),
            }}
          />
        )}
      />
      {singlePick ? null : (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {filteredPeople.map((person, index) => (
            <StatusChip
              key={index}
              id={person.id}
              label={person.name}
              colorIndex={personScheduleColorMap[person.id]}
              onDelete={onDeletePerson}
            />
          ))}
        </Box>
      )}
    </>
  );
};
