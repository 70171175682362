import actions from "../../actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNonVideoEventMutation } from "./useNonVideoEventMutation";
import { addMinutesToTime, getDateString, getISODateTime } from "../../utils/dateTimeUtils";
import { getKeyCancellationReason } from "../../constants/cancelReasons";

export default function useEventFormSubmit({
  selectedClinician,
  rescheduleAllInstances,
  secondaryClinicianUserId,
}) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const videoId = query.get("id");
  const isEditing = !!videoId;

  const { scheduleNonVideoEvent, rescheduleNonVideoEvent, cancelNonVideoEvent } =
    useNonVideoEventMutation();

  const onVideoCallSubmit = (values, eventInfo) => {
    const { isRepeating, repeatEndType, repeatUntilDate, repeatDays, timeUnit } = values;
    const eventDate = getDateString(values.eventDate);
    const startDate = getISODateTime(eventDate, values.startTime, selectedClinician.timezone);
    const endDate = getISODateTime(
      eventDate,
      addMinutesToTime(values.startTime, values.duration),
      selectedClinician.timezone
    );

    const sharedPayload = {
      startDate,
      endDate,
      bcbaEmail: selectedClinician.email,
      clinicianUserId: selectedClinician.clinician_user_id,
      secondaryClinicianUserId,
      billingType: values.serviceType,
      offPlatform: values.event === "doxy-session",
      repeatDays,
      overrideSchedule: true,
      repeatEndType,
      repeatUntilDate,
      timeUnit,
      timeInterval: values.timeInterval,
      isRepeating,
      memberId: values.selectedTeamMember.userId,
    };

    if (isEditing) {
      const resched = {
        ...sharedPayload,
        videoId,
        rescheduleAllInstances: rescheduleAllInstances === "false" ? false : true,
        timezone: eventInfo.client_timezone,
      };

      dispatch(actions.rescheduleVideoCall(resched));
    } else {
      const sched = {
        ...sharedPayload,
        clientId: values?.selectedClient?.id,
        timezone: values.selectedTeamMember.timezone,
      };

      dispatch(actions.scheduleVideoCall(sched));
    }
  };
  const onOtherServicesSubmit = (values) => {
    const {
      isRepeating,
      serviceType,
      repeatEndType,
      repeatUntilDate,
      repeatDays,
      selectedClient,
      repeatNumberOfWeeks,
    } = values;
    const { id: clientId } = selectedClient;
    const eventDate = getDateString(values.eventDate);
    const startDate = getISODateTime(eventDate, values.startTime, selectedClinician.timezone);
    const endDate = getISODateTime(
      eventDate,
      addMinutesToTime(values.startTime, values.duration),
      selectedClinician.timezone
    );

    const sharedPayload = {
      startDate,
      endDate,
      isRepeating,
      clientId,
      clinicianId: selectedClinician.clinician_id,
      clinicianUserId: selectedClinician.user_id,
      serviceType,
      bcbaEmail: selectedClinician.email,
      timezone: values.selectedTeamMember.timezone,
      billingType: values.serviceType,
      override: true,
      repeatDays,
      repeatEndType,
      repeatUntilDate,
      repeatNumberOfWeeks,
    };

    if (isEditing) {
      rescheduleNonVideoEvent.mutate({
        ...sharedPayload,
        rescheduleAllInstances: rescheduleAllInstances === "false" ? false : true,
        id: videoId,
      });
    } else {
      scheduleNonVideoEvent.mutate(sharedPayload);
    }
  };

  const onCancelSubmit = ({
    isNonVideoEvent,
    eventInfo,
    cancelAllInstances,
    message,
    responsibleForCancellation,
  }) => {
    if (isNonVideoEvent) {
      cancelNonVideoEvent.mutate({
        id: eventInfo.id,
        message,
        cancelAllInstances,
        responsibleForCancellation,
      });
    } else {
      dispatch(
        actions.cancelVideoCall({
          message,
          responsibleForCancellation: getKeyCancellationReason(responsibleForCancellation),
          id: eventInfo.id,
          clinicianUserId: eventInfo.clinician_user_id,
          cancelAllInstances,
        })
      );
    }
  };

  const onSubmit = (values, eventInfo) => {
    if (values.event !== "other-services") {
      onVideoCallSubmit(values, eventInfo);
    } else {
      onOtherServicesSubmit(values);
    }
  };

  return {
    onSubmit,
    onSubmitLoading: scheduleNonVideoEvent.isLoading || rescheduleNonVideoEvent.isLoading,
    onSubmitSuccess: scheduleNonVideoEvent.isSuccess || rescheduleNonVideoEvent.isSuccess,
    onSubmitError: scheduleNonVideoEvent.isError || rescheduleNonVideoEvent.isError,
    onCancelSubmit,
    onCancelSubmitLoading: cancelNonVideoEvent.isLoading,
    onCancelSubmitSuccess: cancelNonVideoEvent.isSuccess,
    onCancelSubmitError: cancelNonVideoEvent.isError,
  };
}
